<template>
	<article class="mt-15 student__dashboard--slider">
		<v-tabs
			v-model="currentItem"
			light
			background-color="transparent"
			:slider-color="$store.getters.theme.mainAccentColor"
			slider-size="8"
			color="#000"
			class="organization-settings-tab-buttons-container"
		>
			<v-tab
				v-for="(tab, index) in tabs"
				:key="index"
				:href="`#${tab.component}`"
				class="organization-settings-tab-button primary-text"
				@click="currentItem = tab.component"
			>
				{{ tab.title }}
			</v-tab>
		</v-tabs>

		<v-row class="justify-space-between mt-7">
			<v-col cols="3">
				<v-select label="Standard"></v-select>
			</v-col>

			<v-col cols="3">
				<v-text-field
					v-model="searchText"
					class="search-course"
					placeholder="Start typing to Search"
					:loading="isLoading"
					append-icon="mdi-magnify"
				></v-text-field>
			</v-col>
		</v-row>
		<v-tabs-items
			v-model="currentItem"
			class="organization-settings-tabs-container background-transparent"
		>
			<v-tab-item
				v-for="tab in tabs"
				:key="tab.title"
				:value="tab.component"
				class="organization-settings-tab"
			>
				<keep-alive>
					<component
						:is="tab.component"
						:searchText="searchText"
						@startCourse="startCourse"
						@downloadCetificate="downloadCetificate"
					/>
				</keep-alive>
			</v-tab-item>
		</v-tabs-items>
		<form-message v-if="message">{{ message }}</form-message>
	</article>
</template>

<script>
import { post } from "@/util/requests/post";
import StudentDashboardTabsAnalytics from "./StudentDashboardTabsAnalytics.vue";
import StudentDashboardTabsCourses from "./StudentDashboardTabsCourses.vue";
import { mapGetters } from "vuex";

export default {
	name: "StudentDashboardTabs",
	components: {
		StudentDashboardTabsAnalytics,
		StudentDashboardTabsCourses
	},
	computed: {
		...mapGetters(["isLoading", "startedCourses", "userId", "organization"])
	},

	data() {
		return {
			currentItem: "StudentDashboardTabsAnalytics",
			tabs: [
				{
					title: "Course Analytics",
					value: "analytics",
					component: "StudentDashboardTabsAnalytics"
				},
				{
					title: "My Courses",
					value: "courses",
					component: "StudentDashboardTabsCourses"
				}
			],
			searchText: "",
			message: ""
		};
	},

	methods: {
		changeItem(component) {
			this.currentItem = component;
		},
		async createReport(courseId) {
			try {
				const {
					data: { data }
				} = await post("/report", {
					user_id: this.userId,
					organization_id: this.organization.id,
					completed_at: "",
					course_id: courseId
				});
				localStorage.setItem(`reportId[${courseId}]`, data.id);
				return data;
			} catch (error) {
				console.error(error);
			}
		},
		async startCourse(courseId) {
			if (localStorage.getItem(`courseData[${courseId}]`)) {
				await this.resumeCourse(courseId);
				return;
			}
			try {
				await this.$store.dispatch("setIsLoading", true);
				const report = await this.createReport(courseId);
				const {
					data: { data }
				} = await post("/student_course", {
					user_id: this.$store.state.user.id,
					course_id: courseId,
					page_id: 1,
					report_id: report.id
				});
				await this.$store.dispatch("getStartedCourses");
				await this.$router.push({
					name: "Course",
					query: {
						course: courseId,
						studentCourse: data.id,
						report_id: report.id
					}
				});
			} catch (error) {
				console.error(error);
				this.message = error;
			}
			await this.$store.dispatch("setIsLoading", false);
		},
		async downloadCetificate(courseId) {
			console.log("Download cert", courseId);
		},
		async resumeCourse(courseId) {
			try {
				const { id: studentCourseId } = this.startedCourses.find(
					el => el.course_id === courseId
				);
				await this.$store.dispatch("setIsLoading", true);

				await this.$router.push({
					name: "Course",
					query: {
						course: courseId,
						studentCourse: studentCourseId
					}
				});
			} catch (error) {
				console.error(error);
			}
			await this.$store.dispatch("setIsLoading", false);
		}
	},
};
</script>

<style></style>
